import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import NextLink from 'next/link';
import router from 'next/router';
import { oneLine } from 'common-tags';

import { Button, Caption, Icon, Link } from '@/atoms';
import { SearchBoxLocations } from '@/molecules';

import { gtmPush } from '@/utils';

import type { TypeTextColorVariant } from '@/components/types';
import type { TypeLocation } from '@/molecules/search-box-locations/types';
import type { FunctionComponent } from 'react';
import type { TypeHeroProps } from './types';

const ReactHlsPlayer = dynamic(() => import('react-hls-player'), {
  ssr: false,
});

const VARIANT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: 'text-primary-400',
  secondary: 'text-secondary-400',
  alternative: 'text-white',
};

const PopUpBookingLavanda = dynamic(
  () => import('@/organisms/pop-up-booking-lavanda'),
  { ssr: false }
);

const SearchBox = ({
  searchBox,
}: {
  searchBox: TypeHeroProps['searchBox'];
}) => {
  const [selectedProvince, setSelectedProvince] = useState<
    TypeLocation | undefined
  >({
    id: -1,
    href: '#',
    label: '',
  });

  const [selectedCity, setSelectedCity] = useState<TypeLocation | undefined>({
    id: -1,
    href: '#',
    label: '',
  });

  const [abailablesCities, setAbailableCities] = useState({
    cities: searchBox?.cities?.citiesList?.find((city: any) =>
      city.includes(selectedProvince?.id ?? -1)
    ),
    ...(searchBox?.cities?.defaultValue
      ? { defaultValue: searchBox?.cities?.defaultValue }
      : { placeholder: searchBox?.cities?.placeholder }),
  });

  useEffect(() => {
    setAbailableCities({
      cities: searchBox?.cities?.citiesList?.find((city: any) =>
        city.includes(selectedProvince?.id ?? -1)
      ),
      ...(searchBox?.cities?.defaultValue
        ? { defaultValue: searchBox?.cities?.defaultValue }
        : { placeholder: searchBox?.cities?.placeholder }),
    });
  }, [selectedProvince]);

  const onCitiesChanges = (city: any) => {
    setSelectedCity(city);
  };

  const onProvincesChanges = (value: any) => {
    setSelectedProvince(
      searchBox?.provinces?.values?.find((province) => province.id === value.id)
    );
    setSelectedCity(undefined);
  };

  useEffect(() => {
    setAbailableCities({
      cities: searchBox?.cities?.citiesList?.find((city: any) =>
        city.includes(selectedProvince?.id ?? -1)
      ),
      ...(searchBox?.cities?.defaultValue
        ? { defaultValue: searchBox?.cities?.defaultValue }
        : { placeholder: searchBox?.cities?.placeholder }),
    });
  }, [searchBox]);

  const onSearchHandler = () => {
    gtmPush({
      event: 'booking_search',
      provincia: selectedProvince?.label ?? '',
      edificio: selectedCity?.label ?? '',
      tipo: '',
      fecha: '',
    });

    if (selectedCity) {
      router.push(selectedCity.href);
    } else if (selectedProvince) {
      router.push(selectedProvince.href);
    }
  };

  const { t } = useTranslation('common');

  return searchBox ? (
    <div className="flex w-full flex-col justify-center">
      <div className="my-8 grid w-full grid-cols-1 gap-4 text-left md:mb-10 md:grid-cols-2 md:gap-8">
        <SearchBoxLocations
          //  eslint-disable-next-line
          // @ts-ignore
          cities={abailablesCities}
          provinces={searchBox.provinces}
          onCitiesChange={onCitiesChanges}
          onProvincesChange={onProvincesChanges}
        />
      </div>

      <div className="h-12" />
      <div className="flex justify-center">
        <Button
          onClick={onSearchHandler}
          variant="secondary"
          size="large"
          disabled={selectedProvince?.id === -1}
          className="w-full md:w-auto"
        >
          {t('components.organisms.hero.searchButton')}
        </Button>
      </div>
    </div>
  ) : null;
};

/**
 * Hero
 */
export const Hero: FunctionComponent<TypeHeroProps> = ({
  className = '',
  caption,
  title,
  button,
  link,
  backgroundImage,
  type,
  subtitle,
  textAling = 'start',
  textColor,
  minHeight = '502px',
  hasScrollButton,
  searchBox,
  hasLavandaPopUp,
  lavandaBooking,
  hasImage,
  video,
  backgroundImageMobile,
}: TypeHeroProps) => {
  const handlerScroll = () => {
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );

    window.scrollTo({
      top: vh,
      behavior: 'smooth',
    });
  };

  const [showLavandaPopUp, setShowLavandaPopUp] = useState(false);

  return (
    <>
      {hasLavandaPopUp &&
        lavandaBooking?.apartmentsByBuilding &&
        lavandaBooking?.buildings && (
          <PopUpBookingLavanda
            defaultBuilding={lavandaBooking.defaultBuilding}
            show={showLavandaPopUp}
            onClose={() => setShowLavandaPopUp(false)}
            {...lavandaBooking}
          />
        )}

      <div
        className={
          type === 'solid'
            ? 'bg-surface-01 text-primary-400'
            : hasImage
            ? 'relative text-white'
            : 'relative flex h-screen  items-center justify-center overflow-hidden'
        }
      >
        {type !== 'solid' && hasImage && backgroundImage && (
          <>
            <div className="hidden md:block">
              <Image
                layout="fill"
                src={backgroundImage}
                objectFit="cover"
                priority
                alt={title}
              />
            </div>
            <div className="block md:hidden">
              <Image
                className="block md:hidden"
                layout="fill"
                src={backgroundImageMobile ?? backgroundImage}
                objectFit="cover"
                priority
                alt={title}
              />
            </div>

            <div
              style={{
                background:
                  'linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%)',
              }}
              className="absolute inset-0"
            />
          </>
        )}
        {type !== 'solid' && !hasImage && video && (
          <>
            {/* Se ha dejado el video a pelo por un problema del wirdpress */}
            {/* eslint-disable-next-line */}
            {/* @ts-ignore */}
            <ReactHlsPlayer
              src="https://bedev-media.s3.eu-west-3.amazonaws.com/wp-content/uploads/2024/03/07150012/BeCasa_Web_Header_1920x1080px_02_comprimido.m3u8"
              autoPlay={true}
              controls={false}
              id="videoHero"
              width="100%"
              height="auto"
              playsInline
              className="absolute hidden min-h-full w-auto min-w-full max-w-none md:block"
              loop
              muted
            />
            {/* eslint-disable-next-line */}
            {/* @ts-ignore */}
            <ReactHlsPlayer
              src="https://bedev-media.s3.eu-west-3.amazonaws.com/wp-content/uploads/2024/03/27121353/becasa-mobile.m3u8"
              autoPlay={true}
              controls={false}
              id="videoHero"
              width="100%"
              height="auto"
              playsInline
              className="absolute min-h-full w-auto min-w-full max-w-none md:hidden"
              loop
              muted
            />
            <div
              style={{
                background:
                  'linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%)',
              }}
              className="absolute inset-0"
            />
          </>
        )}

        <div
          className={oneLine`
            u-wrapper z-10 h-full
            ${textAling === 'center' ? 'grid place-items-center' : ''}
          `}
          style={{ ...(minHeight && type !== 'solid' ? { minHeight } : {}) }}
        >
          <div
            className={oneLine`
              m-auto flex h-auto flex-col justify-between gap-6 bg-cover md:h-fit md:justify-start md:gap-9
              ${
                type === 'essential'
                  ? 'max-w-[630px] px-5 py-[72px]'
                  : 'px-5 pb-9 pt-[72px] md:pt-[104px]'
              }
              ${VARIANT_MAPS[textColor]}
              ${
                textAling === 'start'
                  ? 'justify-start text-start'
                  : textAling === 'center'
                  ? 'items-start justify-start md:items-center md:justify-center md:text-center'
                  : 'justify-end text-end'
              }
              ${className}
            `}
          >
            {caption && (
              <Caption align={textAling} variant={textColor} className="w-max">
                {caption}
              </Caption>
            )}

            <h1
              className="text-heading text-heading-mobile--h1 md:text-heading--h1"
              dangerouslySetInnerHTML={{ __html: title }}
            />

            <p className="text-heading text-heading--h5 mt-4">{subtitle}</p>

            {type === 'default' ? (
              <div className="flex flex-col items-center justify-center gap-6 md:flex-row  md:justify-normal  md:gap-7 ">
                {!hasLavandaPopUp
                  ? button?.src &&
                    button.label &&
                    button.variant &&
                    !hasLavandaPopUp && (
                      <NextLink href={button.src} passHref prefetch={false}>
                        <Button
                          className="font-bold"
                          size="large"
                          as="a"
                          variant={button.variant}
                        >
                          {button.label}
                        </Button>
                      </NextLink>
                    )
                  : button?.label &&
                    button?.variant && (
                      <Button
                        className="font-bold"
                        size="large"
                        variant={button.variant}
                        onClick={() => setShowLavandaPopUp(!showLavandaPopUp)}
                      >
                        {button.label}
                      </Button>
                    )}
                {link?.src && link.label && (
                  <NextLink href={link?.src} passHref prefetch={false}>
                    <Link arrow={true} variant={link.variant}>
                      {link?.label}
                    </Link>
                  </NextLink>
                )}
              </div>
            ) : null}

            <SearchBox searchBox={searchBox} />
          </div>

          {hasScrollButton && (
            <button
              onClick={handlerScroll}
              className="absolute bottom-24 right-0 hidden md:inline-flex"
              aria-label="Scroll down"
            >
              <Icon src="ArrowsDownCircle" height={33} width={33} />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

Hero.displayName = 'Hero';
